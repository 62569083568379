
/********************************************************************************************/

.fee_collection_month {
    position: absolute;
    padding: 1px 8px 1px 8px;
    top: 46px;
}
.div_addfeebox{
    height: auto;
    width: 50vw;
    border:8px solid var(--card_blue);
    border-radius: 20px;
    background-color: whitesmoke;
   
}
.div_addfeebox .inputBox{
    position: relative;
}
.div_addfeebox .inputBox input{
    width: 100% ;
    padding: 8px;
    font-size: 18px;

}
.div_addfeebox .select-field option ,select{
    font-size: 16px;
}
.div_addfeebox h2{
    background-color: var(--primary_color_shade1);
    width: 200px;
    height: auto;
    margin-top: 5px;
    border: 3px solid var(--primary_color_shade2);
    border-radius: 10px;
}
.select-field{
    width: 100%;
    padding: 5px;
    border-radius: 8px;
    margin: 2px 2px;
}
.student_id{
    position: absolute;
    width: 100%;
    background: #6de8a394;
    padding: 0px 5px;
    color: var(--black);
    font-size: 16px;
    font-weight: bold;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.h5-font-mt{
    margin-top: 2px;
    color: white;
}

.left-arrow{
    transform: rotate(-180deg);
    float: left;
    border-radius: 50%;
    border: 1px solid #28a745;
    color: #28a745;
    padding: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;

}
.right-arrow{
    float: right;
    border-radius: 50%;
    border: 1px solid #28a745;
    color: #28a745;
    padding: 5px;
    width: 40px;
    height: 40px;
cursor: pointer;
}

.class-list-box-privelege {
    background-color: #f1f1f1;
    /* padding: 1.01em 12px; */
    margin: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}


/***********************************************************************************************/
.adminf-f-head{
    background-color: #f1f1f1;
    padding: 1em 2px;
    margin:10px 2px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}
.inne-section{
  
    color: rgb(10, 8, 8);
    text-align: center;
    padding-top: 10px;
}
.v-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.vv{
    position: relative;
}
.inne-section img{
    width:100px;
    height: 100px;
    background: #FF5722;
    padding:18px;
    border-bottom-right-radius: 48px;
    border-top-left-radius: 48px;
    border: 3px solid #FFC107;
}

.inne-section h4{
    position: relative;
    height: 48px;
}
.paper-d{
    border: 1px solid #cdddea;
}
.chek{
    width: 40px;
    height: 40px;
    background: #88b93c;
    border-radius: 50%;
    padding: 10px 15px;
}
.chek-in{
    transform: scale(2);
}
.paper-d > tr td{
    padding: 5px 10px;
    font-size: 17px;
}
.po button{
    border: dodgerblue;
}
.text-bott{
    background: #fea223;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    color: #fff;
}
.rr{
    transform: scale(2);
    border-radius: 50%;
}
.bor-exam-l{
    border: 1px solid #000;
    padding: 3px 25px;
    border-radius: 20px;
    font-size: 16px;
    margin: 5px;
}
.pk tr td{
padding:2px 10px;
font-size: 15px;
}
.f-18{font-size: 19px;}
.f-16{font-size: 17px;}
.adm-det p{font-size: 17px; margin: 8px;}

.upd{
    border: double #007bff;
    border-radius: 24px;
    padding: 4px 10px;
    font-size: 15px;
    margin: 5px 0px;
}

.pk tr td p{
margin: 3px;
    }

.inne-section h4 > span{
    font-weight: 300;
    font-size: 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}
.resizef{
    font-size: 20px;

}

.inne-section-1{
  
    color: rgb(10, 8, 8);
    text-align: center;
    padding-top: 10px;
}

.inne-section-1 img{
    width:60%;
    background: #454c76;
    padding:10px;
    font-size: 40px;
    border: 3px solid #FFC107;
    /* border-radius: 50%; */
}
.inne-section-1 h4{
    position: relative;
    height: 48px;
}

.inne-section-1 h4 > span{
    font-weight: 300;
    font-size: 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.row-30-min-bg{

    margin: 0px -30px;
    background: #454c76;
    color: #fff;

}
.row-30-min-bg-red{
    background: rgb(79, 195, 247);
    /* background: #ff5722; */
    color: #fff;

}


/* add css for class list 15-09-2019 */

.card-class{

    background: #454c76;
    color: #fff;
    padding-top: 2em;
}

.class-list-box{

    background-color: #f1f1f1;
    padding: 1.01em 12px;
    margin:5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;

}
.card-class h4, .card-class h2{

    text-align: center;
 

}

.mar-t{
    /* margin: -30px -30px 0px; */
    background: #7a81a9;
}
.class-li a{
    text-decoration: none;
    color: black;
}

.class-li .col-sm-7 p{
    margin-bottom: 0.5px;
}
.fee-collection{
    background: #915906;
    border: none;
    color: #fff;
    padding: 16px 94px;
    font-size: 20px;
    position: absolute;
    left: 35%;
    top:31.5%;
}

.p-0-m-20{
    padding: 0px;
    margin: 20px 0px;
}
.fee-d-details{
    width: 150px;
    height: 150px;
    margin: 10px auto;
    border: double #007bff;
    padding: 20px;
    color: #007bff;
}
.fee-button{
    font-size: 20px;
    padding: 5px 60px;
}
.p-0{padding: 0px;}
.f-45{font-size: 45px;}
.f-24{font-size: 24px;}

.input-customize{
    padding: 10px;
    border: 1px solid #b1adad;
}
.pd-20{
    padding: 20px;
}
.arrow:after {
    content:'';
    display: inline-block;
    width:0;
    height:0;
    border:10px solid transparent;
    vertical-align: middle;
}
.arrow{
    cursor: pointer;
}
.stu-info-roll-circle{
    border: 3px solid #fff;
    border-radius: 50%;
    width: 125px;
    height: 125px;
    margin: 0 auto;
    padding: 4px;
}
.en-adhar{
    color: #FF9800;
    font-family: serif;
}
.arrow:after {
    border-left-color: #fefefe;
    margin: 0px 10px;

}
.search-text{

    padding: 11px;
    background: #464d76;
    color: #fff;

}
.p-b-margin p{

        margin-top: 0;
        margin-bottom: 0rem;
        font-size: 11px;
}
.h5-font-siz{
    font-size: 12px;
}
.a-decoration-none,.a-decoration-none:hover{

    color: #fff;
    text-decoration: none;

}
.stu-info-main-bg{

    background:#123abcbf;
}
.st-list-roll-no{
    background: #fff;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 20px;
}
.p-b-mar{
    padding: 0px 15px;
}
.p-b-mar p {
    font-size: 15px;
    margin-bottom: 0px;
    
}
.stu-info-main-bg-1{
    background: rgba(18,58,188,0.75);
    padding: 5px;
}
.outstanding-bg{
    background:#007bff;
    padding: 8px;
    margin: 0px 5px;
}

.stu-info-main-bg-1 p{
    color: #fff;
    font-size: 15px;

}
.th-td-p th,.th-td-p td{
    padding: 5px;
    border: 1px solid #dbd7d7;
}
.th-td-p tr:nth-child(odd){
    background:#f7f7f7;

}
.pt-45{
    padding-top:15px;
}
.collect-button-1 {
    background: #7a81a9;
    margin: 0px -30px;
}
.stu-info-pic{
    width: 70%;
    padding: 5px;
    border: 3px solid #fff;
    margin: 3% 14%;
}
.stu-info-pic img{
width: 100%;
height: 150px;
}
.or-cust{
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
    font-size: 15px;
    padding: 15px 8px;
    background: #7a81a9;
    border-radius: 50%;
    color: #fff;
    margin: -5px auto;
}

.stu-info-detail{
    padding: 4px 10px;
    background: #007bff;
    color: #fff;
    text-align: center;
}

/* Add css for payment history  */
.payment-history-sec{
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,1),0 2px 10px 0 rgba(0,0,0,0.89)!important; */
    margin: 0 auto;
    padding: 25px;
    background: #464d76;
}
.payment-hist-6-sec {
    background: #fff;
    padding: 25px;
}
.payment-hist-student-details{

    padding: 25px;
    border: 1px dotted #fff;
    margin: 10px 20px;
}
.payment-trans-history{
        background: #fff;
        width: 60px;
        height: 60px;
        font-weight: 900;
        padding: 5px;
        border-radius: 50%;
        color: #7c81a9;
        margin: 0 auto;
        font-size: 30px;
}
.pament-hist-text p {
    color: #fff;
    font-size: 15px;
    padding-top: 10px;
    margin: 0px;
}
.pament-hist-text a{
    text-decoration:none;
}
.payment-details{
    background: #fff;
    padding:36px;
    border-right: 1px solid #afaaaa;
    margin-bottom:5px;
}
.payment-details p{
    color: #404040;
    margin-bottom: 0px;
}
.payment-details-check{
    margin-bottom:5px;
    border-right: 1px solid #afaaaa;
}
.input-group {
    margin-bottom: 10px;
}
.heading-with-bg-w{
    background: #464d76;
    padding: 10px;
    margin: 10px 0px;
    color: #fff;
    clear: both;
}
.col-md-10 .form-control{
    margin-left: 2%;
}
.admin-menu-img-size-1 {
    width: 15%;
    margin: 0px 10px;
    /* background: #464d76; */
    padding: 2px;
    border:3px solid #fff;
}
.admission-dashboard-img{

    width: 65%;
    margin: 0 15%;   
}
.card-class-w img{
    width: 100%;
    height:100%;
    border: 1px solid #454c76;
    padding: 5px;
}

/* add css for student details */
.content-customize-area-student-details{
    padding: 30px 0px;
    margin-top: 0px!important;
}
.p-40{
    padding: 40px;
}
.img-phto {
    width: 60%;
    height: 155px;
    margin: 10px;
}
.border-l-r{
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    text-align: center; 
}
.photo-row-bottom{
    padding: 5px;
    color: #2d2c2c;
    border-top: 1px dotted #454c76;
    border-bottom: 1px dotted #454c76;
}
.stu-details table{

width: 100%;

}
.stu-details table tr{
    border: 1px solid #2d2c2c;
}
.stu-details table td{
padding: 10px;
}
.stu-details{
    padding: 0px 38px;
}
.contact-details .col-sm-4,.contact-details .col-sm-8{
 border-right: 1px solid #2d2c2c;

}
.family-p{
    font-size: 18px;
    margin: 10px;
    font-weight: 500;
}
.family-p-bottom{
    font-size: 18px;
    margin: 10px;
    font-weight: 400;
}
.family-details-poto img{
    width: 100%;
}
.family-details-poto{
    width: 175px;
    height: 175px;
    border: 5px solid #454c76;
    padding: 4px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
}
.contact-details-row{
    background: #454c76;
    color: #fff;
    margin: 20px -20px;

}
.class-list-box-c {
    /* background-color: #f1f1f1; */
    padding: 1.01em 12px;
    margin: 5px;
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important; */
    border: 1px solid #d6c2c2;
}
.current-address-row{

    background: #67a28b;
    color: #fff;
    margin: 20px;
}
.row-p-20{

    padding: 20px;

}

/* Notce board css */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height:25px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.add-notice-text-p{
    width: 100%;
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
}
.s-change{
    margin: 0 auto;
    width: 28%;
    padding: 8px;
    font-size: 17px;
    border-radius: 30px;
}
.s-pad-30{
    padding: 30px 15px;
}
.notice-order{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px; 
}
.heading-with-bg-w-notice {
    background: #464d76;
    padding: 5px;
    margin: 0px 0px 45px;
    color: #fff;
    clear: both;
}
.ch-in{
    transform: scale(2);
}
.ch-text-s{
    font-size:16px;
}
.ment{
    background: azure;
    padding: 15px;
    width: 100%;
}
.b-color{
    background: rgba(52, 67, 116, 0.95);
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 15px;
    font-size: 20px;
}
.b-color-w{
    background: rgba(255, 255, 255, 0.95);
    color: rgb(116, 72, 124);
    width: 100%;
    padding: 15px;
    font-size: 20px;
}
/* add css for selectmultiple students */

.selectMultiplst-h4{
    background: #c8c2c2;
    padding: 10px;
    font-size: 16px;
}
.selectmuliple-class-check{
    transform: scale(2);
}

/* Add css for Attendance */

.attend{
    padding: 10px;
    width: 60px;
    height: 60px;
    background: antiquewhite;
    border:double;
    color: black;
    margin: 5px auto;
    font-size: 24px;
}
.card-class-att {
    background: #454c76;
    color: #fff;
    padding-top: 5px;
}
.attend-c{
    padding: 10px;
    width: 60px;
    height: 60px;
    background: antiquewhite;
    border-radius: 50%;
    color: black;
    margin: 5px auto;
    font-size: 24px;
}
.active-attendance{
    display: none;
}
.attend-status{
    background: #fff;
    padding: 14px;
}
.atten-1{
    padding: 10px 4px;
}
.atten-2{
    border-right: 1px solid #c0bbbb;
    font-weight: 600;
}
.atten-3{
    padding: 0px;
    border-radius: 10px;
    cursor: pointer;
    background: #454c76;
    color: #fff;
}
.butt-p1{
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    padding: 4px 13px;
    color: #000;
    margin: 10px;
}
.butt-p2{
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    padding: 4px 13px;
    color: #000;
    margin: 10px;
}
.butt-p3{
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    padding: 4px 13px;
    color: #000;
    margin: 10px;
}
.butt-p4{
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    padding: 4px 13px;
    color: #000;
    margin: 10px;
}
.bb-switch{
    position: absolute;
    right: 60px;
}
.attend-button{
    float: right;
    border-radius: 21px;
    font-size: 14px;
    padding: 6px 20px;
}
.top-bottom-center{
    display: table-cell;
    height: 105px;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
}
.top-bottom-center-present{
    transform: scale(1.7);
    border-radius: 50%;
    background: #2f9f48;
    width: 30px;
    padding: 5px;
    color: #fff;
    height: 30px;
}
.top-bottom-center-sr{
    transform: scale(1.2);
    border-radius: 50%;
    background: #a5b0a7;
    width: 30px;
    padding: 0px;
    color: #fff;
    height: 30px;
}
.after-attendance-sec{
    background: aliceblue;
    padding: 20px 10px;
}
.tbl td:nth-child(even){
    background-color: #d4e9ff;
    text-align: center;
    padding: 10px;
    font-weight: 500;
}
.tbl td:nth-child(odd){
    background-color: #ecfad6;
    text-align: center;
    padding: 10px;
    font-weight: 500;
}
.tbl td button{
    padding: 5px;
    width: 30%;
    border-radius: 10px;
    font-weight: 700;
}
.hideen-taken-attendance{
    display: none;
}
.posi-rel{
    position: relative;
    border: 1px solid #dddcdc;
    border-radius: 5px;
}
.bg-reg-ad-no{
    background: #474e7666;
    color: #000;
    margin:50px 0px;
    padding: 10px;
}
.bg-st-img-left{
    background: #ececec;
    padding-top: 20px;
}
.ad-radio{
    transform: scale(2.5);
    margin-left: -10px!important;
}
.freg{
    font-weight: 700!important;
    font-size: 16px;
}

.up-img{
    position: absolute;
    font-size: 12px;
    right: 0px;
    top: 4px;
    border-radius: 20px;
    background: #efeeeb;
    padding: 4px;
    border: 1px solid #868282;
    cursor: pointer;
}
.button-circlea{
    border: 2px solid #337ab7;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    font-size: 40px;
    color: #7a81a9;
    margin: 10px auto;
}
.button-circlep{
    border: 2px solid #28a745;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    font-size: 40px;
    color: #28a745;
    margin: 10px auto;
}
.present-status{
    background: #28a745;
    padding: 10px;
    font-weight: 500;
    position: relative;
}
.prsent-status{
    width: 40px;
    height: 40px;
    background: antiquewhite;
    padding: 10px 18px;
    border-radius: 50%;
    font-size: 21px;
}
.white-bg-button{
    background: #fff;
    padding-top: 20px;
}
.student-attend-bottom{
    background: #f7ffff;
    margin: -5px 5px;
    padding: 5px;
}
.student-attend-bottom button{
    border-radius: 20px;
    padding: 8px 25px;
}
.update-exam-status{
    border-radius: 5px;
    padding: 8px;
    border: double #7a81a9;
    color: #6f746f;
}
.examList-bg{
    background-color: #f1f1f1;
    padding: 1.01em 12px;
    margin: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}
.bord-l-r{
    border-left: 1px solid #dbd7d7;
    border-right: 1px solid #dbd7d7;
}
.bg-student-prsent{
    padding: 20px 0px;
    background: aqua;
}
.adt-roll{
    width: 45px;
    height: 45px;
    padding: 8px;
    background: #28a745;
    border-radius: 50%;
    font-size: 15px;
    margin: 5px auto;
    color: #fff;
}
.b-bg-c-bg{
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
    margin-bottom: 20px;
}
.button-circleli{
    border: 2px solid #88b93c;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    font-size: 40px;
    color: #88b93c;
    margin: 10px auto;
}
.button-circleall{
    border: 2px solid #464d76;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    font-size: 40px;
    color: #464d76;
    margin: 10px auto;
}

/* add css exam paper setting */
.exam-p-setting-cls{
    border-radius: 4px;
    border: 1px solid #fff;
    padding:10px 2px;
    text-align: center;
    height: 50px;
    margin-top: 3px;
}
.exam-p-setting-sub{
    border-radius: 4px;
    border: 1px solid #fff;
    background:#b1adad ;
    padding: 5px;
    text-align: center;
    height: 35px;
    color: #fff;
}
.exam-section-1{
    background: #77b9f1;
    padding: 5px;
}
.exam-section-2{
    padding: 25px 0px;
}
.exam-section-3{
    padding: 10px;
    background: aliceblue;
}
.exam-section-4{
    padding: 10px;
    background: #f0f0f0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}
.fee-dash-b{
    padding: 10px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}

/* Add css for myprofile  */

.myprofile-img-div{
    width: 200px;
    height: 220px;
    margin: 0 auto;
}
.myprop{
    margin: 40px auto;
   
}
.photo-st{
    width: 130px;
    margin: 13px auto;
    height: 130px;
}
.sect-bg-clean-qla {
    box-shadow: 1px 0px 8px #00000026;
    border: 1px solid #eee;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 2px 0px;
}

.myprop img{
    border-radius: 50%;
}
.holiday-tb th,.holiday-tb td {

    padding: 15px;
  }
  .holiday-tb tr:nth-child(even){
      background: #dbd7d7;
      color:#337ab7;
  }
  .color-a{
    color: #337ab7;
  }
.school-update{
    background: azure;
    padding: 45px 0px !important;
    border-radius: 20px;
    border: double #28a745;
}
.reply-comment{
    border-radius: 43px;
    border: double #337ab7;
    margin:0px 0px;
    width: 12%;
    font-size: 14px;
}
.replayer{
width:30%;
}
.pendding{
    width: 50px;
    height: 50px;
    background: burlywood;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    padding: 3px;
    border-radius: 50%;
}
.bor-r-leave{
    border-right: 1px solid #e3e3e3;
}
.class-white{
    border: 1px solid #fff;
}
.bor-t-leave{
    border-top: 1px solid #e3e3e3;
}
.bor-b-leave{
    border-bottom: 1px solid #e3e3e3;
    padding: 10px 0px;
}
.text-medium{
    font-size: 16px;
}
.pendding-top-row{
    background: #28a745;
    padding: 10px;
    font-size: 15px;
}
.cur{
    cursor: pointer;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 20px;
    text-transform: uppercase;
}
.sect-bg-clean{
    box-shadow: 1px 3px 8px #00000026;
    border: 1px solid #eee;
    padding: 5px 10px;
    border-radius: 20px;

}
.heading-with-bg-w{
    font-size: 22px;
}
.border-bott{
    border-bottom: 1px dotted #a3a1a1;
    padding: 10px;
}

.w-100{
    width: 100%;
}
.f-w-300{
    font-weight: 300;
}
.staff-profile-detail{
    border: 1px solid #fff;
    padding: 10px;
}
.form-com{
    width: 100%;
    height: 130px;
    border-radius: 10px;
}
.success-m{
    margin: 10px auto;
    background: #fff;
    border-radius: 10px;
    padding: 55px;
    height: 600px;
}
.s-mt-100{
    margin-top: 100px;
}

.sp-10{padding: 10px;}
.im img{
    width: 100%;
}
.drop-h{
    background: #007bff3d;
    padding: 8px;
    color: #fff;
    font-size: 18px;
}

.form-ii{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    border: 1px solid #cecccc!important;
}
.class-set{
    background: #505a95;
    color: #fff;
    padding: 5px 22px;
}
.add-cls-sec{
    border-radius: 19px;
    background: #fff;
    color: crimson;
    font-size: 16px;
    padding: 4px 15px;
    margin-top: 6px;
    font-weight: 400;
    border: double #a5a1a1;
}
.divid-sec{
    background-color: #f1f1f1;
    padding: 1.01em 28px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
    margin: 3px;
}
.dt-text-p{

    padding: 10px;
    border: 1px solid #000;
    margin: 10px;
}
.htr{
    background-color: #f1f1f1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}
.mt-10{
    margin-top: 10px;
}
.fee-month{
    width: 100px;
    margin: 3px auto;
    height: 100px;
    border: 2px solid #337ab7;
    text-align: center;
    border-radius: 50%;
    padding: 15px;
    font-weight: 700;
}
.fee-m-session {
    background: #88b93c;
    border-radius: 4px;
    color: #fff;
    position: absolute;
    padding: 3px 20px;
    top: 80px;
    margin: 0px 0px 0px -12px;
}

/* add css for fee dashboard */
.fee-d-date-s{
    background: #e8f1ee;
    padding: 10px;
    text-align: center;
}
.fee-d-button-bg-s{
    background: #aad0f8;
    padding: 10px;
}
.border-left-primary {
    border-left: .25rem solid #4e73df!important;
}
.pb-2, .py-2 {
    padding-bottom: .5rem!important;
}

.pt-2, .py-2 {
    padding-top: .5rem!important;
}
.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}
.border-left-success {
    border-left: .25rem solid #1cc88a!important;
}
.border-left-info {
    border-left: .25rem solid #36b9cc!important;
}
.border-left-warning {
    border-left: .25rem solid #f6c23e!important;
}
.col-xs-d10,
.col-sm-d10,
.col-md-d10,
.col-lg-d10 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 11.11%;
    flex: 0 0 11.11%;
    max-width: 11.11%;
}

.bg-sdk{
    background: #c5ffd2;
    padding: 10px 0px 0px;
}
.p-10{padding: 10px;}
.lin .btn{margin-bottom: 0px;}
.lin a:hover{
    background-color: #007bff!important;
}
.clf{
    border: double;
    padding: 5px 10px;
}
.cld{
    border: double;
    padding: 0px 10px;
    border-radius: 20px;
}
.otherfeesetting-b{
    border-radius: 20px;
    padding: 5px 44px;
    font-size: 21px;
    float: right;
}
.ft-p{
    background: #fff;
    padding: 20px;
    text-align: center;
    font-size: 18px;
    height: 120px;
    border: double rgba(128, 149, 215, 0.75);
}
.w-h-size{
    width: 150px;
    height: 150px;
}
.ad-tab{
    width: 100%;
    margin: 20px 0px;
    text-align: center;
}
.heit{
    height: 160px;
}
.w-h-size-1 {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.border-1{
  border: 2px solid #000;  
}
.adm-det{
    border: 1px solid #000;
    margin: 10px -13px;
}
.adm-det-Le{
    border-left: 1px solid #000;
}
.adm-det-Ri{
    border-right: 1px solid #000;
}
#divehd{
    display: none;
}
.ht{

    width: 75%;
    margin: 0 auto;
}
.vvb{
    background:url(../../resources/images/EduOk.png);
    background-repeat: no-repeat;
    background-position: center;
}
.bg-white-11{
    background:#fffffff0;
}
.border-1b{
    border: 1px solid #000;
    padding: 5px;
}
.margin-2{
    margin: 2px;
}
.mmr-bt{
    border-top: 1px solid #000;
    margin: 0px -12px;
    padding-top:5px;
}
.f-cursive{
    font-family: cursive;
}
.tt-t{
    text-align: justify;
    padding: 5px;
}
.pdl-5{
    padding-left: 5px;
}
.mr-10{
    margin: 10px;
}
.height-58{
height: 58px;
}
.ft-op{
    font-size: 23px!important;
}
.tbl-wh{
    width: 100%;
    height: 300px;
}
.mmrk p,.mmrk h4,.mmrk h2,.mmrk h3,.mmrk th,.mmrk td {
    font-family:serif;
}

.f-600{
    font-weight: 600;
}
.ht-100{
    height: 100px;
}
.mmrk-student-details{
    word-spacing: 5px;
    letter-spacing: 3px;
    font-size: 25px;
    font-weight: 600;
    margin-left: 275px;
}

.name-st{
    font-size: 23px!important;
}
.D1{
    font-size: 21px!important;
    color: #4d4d4d;
}
.w-h-size-mark {
    width: 150px;
    height: 178px;
}
.f-25{
font-size: 25px;

}
.f-f{
    font-family: sans-serif;}

.f-21{
    font-size: 21px;   
}
.h-40 {
    height: 40px;
    color: #4d4d4d;
}
.bor-b{border-bottom: 1px solid #000;}
.bor-t{border-top: 1px solid #000;}
.bor-t2{border-top: 2px solid #000;}
.bor-rr{border-right: 2px solid #000;}
.tbb > tr th:nth-child(1),.tbb > tr td:nth-child(1),.tbb > tr th:nth-child(2),.tbb > tr td:nth-child(2),
.tbb > tr th:nth-child(3),.tbb > tr td:nth-child(8),.tbb > tr th:nth-child(4),.tbb > tr td:nth-child(14){
    border-right: 2px solid #000;   
}
/* Add new css 28-06-2020 */

.bg-yellow{
    background-color: yellow;
}
.du-old{
    padding: 10px;
    background: #d33636;
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.profil-im{
    border-radius: 10px;
    border: 2px solid #ffff;
    padding: 10px;
    background: #d33636;
}

.profil-im img{width: 100%;}

.profil-d{
    border-radius: 10px;
    border: 2px solid #d33636;
}
.profil-d>p{margin-bottom: 0px!important;}

.weight-d7{
    color: #f11919;
    font-weight: 700;
}
.darkkhaki{
    color: darkkhaki;
}
.rld{
    padding: 0px 30px 0px 15px;
}
.rld a{text-decoration: none;}

.fee-receipt-pr tr td{
    padding: 6px 10px;
}
.fee-receipt-pr-1 tr td,.fee-receipt-pr-1 tr th{
    padding: 6px 10px;
}
.th-section{
    width: 120px;
    height: 120px;
    background: #ba7ff1;
    border: 2px solid #bfe3c7;
    border-radius: 50%;
    text-align: center;
    padding: 32px 24px;
    font-size: 18px;
}
.th-section a{
    color: #fff;
}
.mb-100{
    margin-bottom: 100px;
}

.s-bt{
    position: absolute;
    top: 27px;
    left:15px;
}

.right-prive-box{
    background: #007bff;
    padding: 10px;
}
.switch-height-20{
    height: 20px;
}
.switch-height-20 > .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
}
/* Add new css for fee card 26-07-2020 */
.fee-card-tabe th,.fee-card-tabe td{
    padding: 5px 10px;
}
/* Add new css for fee card 01-08-2020 */
.staff-img-path{
    width: 35%;
    border: 1px solid rgba(18,58,188,0.75);
    border-radius: 50%;
    padding: 3px;
}
.left-arrow-light {
    transform: rotate(-180deg);
    float: left;
    border-radius: 50%;
    border: 1px solid #28a7451f;
    color: #28a74526;
    padding: 5px;
    width: 40px;
    height: 40px;
}
.right-arrow-light {
    float: right;
    border-radius: 50%;
    border: 1px solid #28a7451f;
    color: #28a74526;
    padding: 5px;
    width: 40px;
    height: 40px;
}

/* add css for calender in home work componet 04-08-2020 */
ul {list-style-type: none;}
.month {
    padding: 10px 25px;
    width: 100%;
    background: rgb(3, 169, 244);
    text-align: center;
  }
  
  .month ul {
    margin: 0;
    padding: 0;
  }
  
  .month ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  
  .month .prev {
    float: left;
    padding-top: 10px;
  }
  
  .month .next {
    float: right;
    padding-top: 10px;
  }
  
  .weekdays {
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
  }
  
  .weekdays li {
    display: inline-block;
    width: 13.6%;
    color: #666;
    text-align: center;
  }
  
  .days {
    padding: 10px 0;
    background: #eee;
    margin: 0;
  }
  
  .days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    font-size:12px;
    color: #777;
  }
  
  .days li .active {
    padding: 5px;
    background: #1abc9c;
    color: white !important
  }
  
  /* Add css for shift batch 07-08-2020 */
  .rld-28{
    /* padding: 0px 0px 0px 28px; */
    margin-top: 3px;
  }
  .rld-inner-row{
    border: 1px solid #178530;
    padding: 2px;
    border-radius: 10px;
    margin: 0px;
  }

  .cl-3-part{
    background: #329247;
    border-radius: 10px;
    margin: 0px;
  }
  .profil-im-w {
    border-left: 2px solid #ececec;
}
.profil-im-img {
    border-radius: 50%;
    border: 2px solid #ffff;
    padding: 18px;
    background: #4fc3f7;
}
.profil-im-img img {
    width: 100%;
}
.gh-b{
    border: 2px solid #ff6927;
    border-radius: 10px;
}
.stf-bg{
    position: absolute;
    top: 84%;
    width: 100%;
    left: 0%;
    background: #6de8a394;
    padding: 0px 15px;
    color: #fff;
}
.p-checkbox{
    margin: 10px;
    transform: scale(1.5);
}
.upload-icon{
    width: 90%;
    transform: rotate(90deg);
    margin-top: -8px;
    margin-right: -33px;
    cursor: pointer;
}
.selected-stu-1{
    background: #b8b0b0;
    padding: 10px;
}
.inactive-field{
    display: none;
}
.active-field{
    display:block;
}

/* add css for modal in batch shifting  */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
   /* add css for students component 18-08-2020 */
   .bb-r-c{
    border: 1px solid #007bff;
    border-radius: 10px;
   }
   .height-130{
    height: 130px;
   }

   /* Add css for student Details // 03-09-2020 */
.m-0-100{
    padding: 20px 100px;
    background: #fff5f5;
    margin: 80px 100px;
    border-radius: 30px;
}
.photo-st-21{
    width: 200px;
    margin: 12px auto;
    height: 200px;
    border: 10px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}
.cam{
    position: absolute;
    width: 35%;
    top: 120px;
    padding:5px;
    left: 145px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
}
.basic-de{
    background-color: #007bff80;
}
/* Add css for Fee collection Details // 17-09-2020  */
.clHide{
    display: none;
}
.clShow{
    display: block;
}
.oldduelist-img-border{
    width: 100%;
    background: #eee;
    border: 4px solid #fff;
    border-radius: 50%;
}
.addOldDue-Row-1{
    background: #fff;
    border: 2px solid rgba(18,58,188,0.75);
    border-radius: 10px;
}
.form-control-dharam{
    width: 97%;
    height: 40px;
    padding: 11px;
    border-radius: 5px;
    margin: 1px 10px;
    border: none;
}
.form-control-gh{
    width: 100%;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}
.hjkc{
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: rgba(18,58,188,0.75);
    padding: 4px;
    color: #fff;
    font-weight: 700;
    border-radius: 5px;
}
.s000no{
    position: absolute;
    left: 0px;
    top: 77px;
    background: #f0ffffb3;
    width: 97px;
    text-align: center;
    font-weight: 700;
}
.checkbox-due-collecton{
    margin: 0 auto!important;
    width: 36px;
    height: 35px;
}
.due-main-ox{
    width: 45px;
    padding: 5px;
    border-radius: 4px;
    margin: 25px auto;
    height: 45px;
    background: rgba(18,58,188,0.75);
}
.ed-1{
    background: rgba(18,58,188,0.75);
    padding: 5px;
    border-radius: 5px;
    color: #fff;
}
.viv{
    text-align: center;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
}
.d-150{
    background: #fff;
    padding: 50px;
    margin: 0px 150px;
    border-radius: 10px;
}
  /* Add media queries for smaller screens */
  @media screen and (max-width:720px) {
    .weekdays li, .days li {width: 13.1%;}
  }
  
  @media screen and (max-width: 420px) {
    .weekdays li, .days li {width: 12.5%;}
    .days li .active {padding: 2px;}
  }
  
  @media screen and (max-width: 290px) {
    .weekdays li, .days li {width: 12.2%;}
  }
/* Add css for mobile view */

 @media(max-width:768px){
    .mar-t{
        /* margin: -10px -30px 0px!important; */
        background: #7a81a9;
    }
    .fee-collection{
    left: 4%;
    top: 31%;
    }
    .or-cust {
        border: 1px solid #fff;
        width: 50px;
        height: 50px;
        font-size: 15px;
        padding: 15px 8px;
        background: #7a81a9;
        border-radius: 50%;
        color: #fff;
        margin: 5px auto;
    }

}

 /* Add css for print view */

@media print {
    @page {
        size:100%; /* landscape */
        /* you can also specify margins here: */
        margin: 0mm;
        margin-right:0mm; /* for compatibility with both A4 and Letter */
      }
    .page-header,.left-sidebar,
    .content-header,.prin {
        display: none;
    }
    .content {
        margin:0px;
       
    }
    .ht{

        width:100%;
        margin: 0 auto;
    }

    .bg-white-11{
        background:#fffffff0!important;
    }
    .vvb{
        background:url(../../resources/images/EduOk.png)!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
    }
    .fg td{padding: 0px 5px; font-size: 19px;}
    #divcontentakno{
        margin-left:-150px;
         color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }
    html, body {
        height:100vh; 
        overflow: hidden;
      }
.basic-de{
    background-color: #007bff80!important;
}
.dharam{
    border: 1px solid #000;
}
}

